<template>
  <v-container class="mt-4 ml-2" fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="shadowCard">
          <v-card-title primary-title>
            <span class="text-subtitle-1">Mi Retroalimentación</span>
            <v-spacer></v-spacer>

            <v-btn
              color="primary"
              dark
              class="text-capitalize"
              @click="initialize()"
              small
              tile
            >
              <v-icon left small>mdi-refresh</v-icon>
              Actualizar
            </v-btn>

          </v-card-title>
          <v-card-text>
            <v-row justify="space-between">

              <v-col cols="12" md="4" lg="3">
                <label>Selecciona el ciclo</label>
                <v-autocomplete
                  filled
                  dense
                  clearable
                  v-model="ciclo"
                  :items="ciclos"
                  label="Selecciona ciclo actual"
                  persistent-hint
                  hide-details
                  return-object
                  item-text="ciclo"
                  item-value="id_ciclo"
                  single-line
                >
                </v-autocomplete>
              </v-col>

              <v-col cols="12" md="4" lg="3">
                <label>Fecha incio</label>
                <v-text-field
                  label="Fecha de incio"
                  filled
                  dense
                  v-model="fechaini"
                  single-line
                  type="date"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="4" lg="3">
                <label>Fecha final</label>
                <v-text-field
                  label="Fecha de incio"
                  filled
                  dense
                  v-model="fechafin"
                  single-line
                  type="date"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="4" lg="3">
                <label>Selecciona el concepto</label>
                <v-select
                  :items="[{id: 1, concepto: 'NI'},{id: 2, concepto: 'RI'},{id: 3, concepto: 'TODOS'}]"
                  v-model="concepto"
                  label="Concepto"
                  item-text="concepto"
                  item-value="id"
                  filled
                  dense
                  single-line
                ></v-select>
              </v-col>

              <v-col cols="12" md="4" lg="3">
                <label>Selecciona el estatus</label>
                <v-select
                  :items="[{id: 0, concepto: 'Asignado'},{id: 1, concepto: 'Aceptado'},{id: 2, concepto: 'Rechazado'},{id: 3, concepto: 'Todos'}]"
                  v-model="estatus"
                  label="estatus"
                  item-text="concepto"
                  item-value="id"
                  filled
                  dense
                  single-line
                ></v-select>
              </v-col>

              <v-col cols="12" md="4" lg="3">
                <v-text-field
                  name="name"
                  label="Buscar"
                  id="id"
                  filled
                  dense
                  hide-details
                  append-icon="mdi-magnify"
                  v-model="search"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-data-table
                  :headers="headers"
                  :items="filterRetros"
                  class="elevation-0"
                  :search="search"
                  :mobile-breakpoint="100"
                  dense
                >
                  <!-- Valores que ocupan botons, chips, iconos, etc -->
                  <template v-slot:item.estatus="{ item }">
                    <v-chip
                      v-if="item.estatus == 1"
                      small
                      color="green"
                      dark
                    >Aceptado</v-chip>

                    <v-chip
                      v-if="item.estatus == 2"
                      small
                      color="black"
                      dark
                    >Rechazado</v-chip>

                    <v-chip
                      v-if="item.estatus == 0"
                      small
                      color="blue"
                      dark
                    >Asignado</v-chip>
                  </template>

                  <template v-slot:item.concepto="{ item }">
                    <v-chip
                      v-if="item.concepto == 1"
                      small
                      color="grey"
                      dark
                    >NI</v-chip>

                    <v-chip
                      v-if="item.concepto == 2"
                      small
                      color="orange"
                      dark
                    >RI</v-chip>
                    
                  </template>


                  <template v-slot:item.actions="{ item }">
                    <v-btn
                      color="primary"
                      x-small
                      @click="editItem(item)"
                      class="mr-2"
                    >
                      <v-icon small>mdi-magnify</v-icon>
                    </v-btn>
                  </template>

                  <template v-slot:no-data>
                    <v-btn
                      color="primary"
                      @click="initialize"
                      small
                    >
                      Actualizar
                    </v-btn>
                  </template>

                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="dialogEdit"
      max-width="800px"
      persistent
    >
      <v-card>
        <v-card-title>
          <span class="text-subtitle-1">{{ formTitle }}</span>
          <v-spacer></v-spacer>
          <span class="text-subtitle-1">
            <strong>ID: {{ contacto.idretroalimentacion }}</strong>
          </span>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12">
              <label>Selecciona el concepto</label>
              <v-radio-group
                v-model="contacto.concepto"
                row
                hide-details
                readonly
              >
                <v-radio
                  label="NI"
                  :value="1"
                ></v-radio>
                <v-radio
                  label="RI"
                  :value="2"
                ></v-radio>
              </v-radio-group>
            </v-col>

            <v-col cols="12">
              <label>Evaluó: </label><br/>
              <b>{{ contacto.evalua }}</b>
              <br/>
              <br/>

              <label>Evaluado: </label><br/>
              <b>{{ contacto.evaluado }}</b>
              <br/>
              <br/>

              <label>Promedio: </label><br/>
              <b>{{ contacto.promedio }}</b>
              <br/>
              <br/>

              <label>Estatus: </label><br/>
              <v-chip v-if="contacto.estatus == 0" small dark color="blue">Asignado</v-chip>
              <v-chip v-if="contacto.estatus == 1" small dark color="green">Aceptado</v-chip>
              <v-chip v-if="contacto.estatus == 2" small dark color="black">Rechazado</v-chip>
              <br/>
              <br/>

              <label>Mensaje Rechazo: </label><br/>
              <b>{{ contacto.mensaje_rechazo }}</b>
              <br/>
              <br/>
            </v-col>

            <v-col cols="12">
              <v-card class="shadowCard">
                <v-card-title class="text-subtitle-1">Bandeja de entrada de WhatsApp</v-card-title>
                <v-card-text class="black--text">
                  <v-row>

                    <v-col cols="12" md="6" class="mb-4">
                      <v-img :src="url + contacto.foto" contain max-height="300"/>
                    </v-col>

                    <v-col cols="12" md="6">
                      <label class="grey--text darken-5">A continuación, solo elige la opción que mejor corresponda entre un SI y un NO.</label>
                      <br/>
                      <br/>
                      <label v-if="contacto.concepto == 1">
                        ¿Cuenta con mensajes sin leer de <b>más de una hora?</b>
                      </label>
                      <v-radio-group
                        v-if="contacto.concepto == 1"
                        v-model="contacto.p1"
                        row
                        dense
                        hide-details
                        readonly
                        class="mb-2 mt-0"
                      >
                        <v-radio
                          class="pt-0"
                          label="SI"
                          :value="1"
                        ></v-radio>
                        <v-radio
                          class="pt-0"
                          label="NO"
                          :value="2"
                        ></v-radio>
                      </v-radio-group>

                      <label v-if="contacto.concepto == 1">
                        ¿Cuenta con {{ contacto.concepto == 1 ? 'prospectos' : 'alumnos' }} <b>en visto?</b>
                      </label>
                      <v-radio-group
                        v-if="contacto.concepto == 1"
                        v-model="contacto.p2"
                        row
                        dense
                        readonly
                        hide-details
                        class="pt-0 mt-0"
                      >
                        <v-radio
                          label="SI"
                          :value="1"
                        ></v-radio>
                        <v-radio
                          label="NO"
                          :value="2"
                        ></v-radio>
                      </v-radio-group>
                      
                      <v-textarea
                        v-model="contacto.p3"
                        :rows="6"
                        auto-grow
                        filled
                        dense
                        readonly
                        label="Escribe una Retroalimentación"
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12" v-for="(folios , i ) in contacto.contactos" :key="i">
              <v-card class="shadowCard">
                <v-card-title class="text-subtitle-1">Contacto #{{ i + 1 }}</v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <b>Folio:</b> {{ folios.folio }}<br/>
                      <b>Telefono:</b> {{ folios.telefono }}<br/>
                    </v-col>

                    <v-col cols="12" md="6" class="mb-4">
                      <v-img :src="url + folios.foto" contain aspect-ratio="2" @click="verImagen( folios.foto )" />
                    </v-col>


                    <v-col cols="12" md="6">
                      <label v-if="contacto.concepto == 1">¿Hay mensajes Robotizados?</label>
                      <v-radio-group
                        v-if="contacto.concepto == 1"
                        v-model="folios.valor1"
                        row
                        dense
                        hide-details
                        readonly
                        class="mt-0 mb-4"
                      >
                        <v-radio
                          label="SI"
                          :value="1"
                        ></v-radio>
                        <v-radio
                          label="NO"
                          :value="2"
                        ></v-radio>
                      </v-radio-group>

                      <label v-if="contacto.concepto == 1">¿El tiempo de respuesta entre el cliente y el asesor es adecuado?</label>
                      <v-radio-group
                        v-if="contacto.concepto == 1"
                        v-model="folios.valor2"
                        row
                        dense
                        readonly
                        hide-details
                        class="mt-0 mb-4"
                      >
                        <v-radio
                          label="SI"
                          :value="1"
                        ></v-radio>
                        <v-radio
                          label="NO"
                          :value="2"
                        ></v-radio>
                      </v-radio-group>

                      <label v-if="contacto.concepto == 1">¿El asesor usa herramientas adicionales en su seguimiento como audios e imágenes?</label>
                      <v-radio-group
                        v-if="contacto.concepto == 1"
                        v-model="folios.valor3"
                        row
                        dense
                        hide-details
                        readonly
                        class="mt-0 mb-4"
                      >
                        <v-radio
                          label="SI"
                          :value="1"
                        ></v-radio>
                        <v-radio
                          label="NO"
                          :value="2"
                        ></v-radio>
                      </v-radio-group>

                      <label v-if="contacto.concepto == 1">¿El asesor usa los documentos de la escuela de manera correcta (Plan de estudios, calendarios de pago , métodos de pago)?</label>
                      <v-radio-group
                        v-if="contacto.concepto == 1"
                        v-model="folios.valor4"
                        row
                        dense
                        hide-details
                        readonly
                        class="mt-0 mb-4"
                      >
                        <v-radio
                          label="SI"
                          :value="1"
                        ></v-radio>
                        <v-radio
                          label="NO"
                          :value="2"
                        ></v-radio>
                      </v-radio-group>

                      <label v-if="contacto.concepto == 1">¿El asesor se dirige con formalidad al prospecto?</label>
                      <v-radio-group
                        v-if="contacto.concepto == 1"
                        v-model="folios.valor5"
                        row
                        dense
                        hide-details
                        readonly
                        class="mt-0 mb-4"
                      >
                        <v-radio
                          label="SI"
                          :value="1"
                        ></v-radio>
                        <v-radio
                          label="NO"
                          :value="2"
                        ></v-radio>
                      </v-radio-group>

                      <label v-if="contacto.concepto == 2">¿Hay seguimientos Robotizados?</label>
                      <v-radio-group
                        v-if="contacto.concepto == 2"
                        v-model="folios.valor6"
                        row
                        dense
                        hide-details
                        readonly
                        class="mt-0 mb-4"
                      >
                        <v-radio
                          label="SI"
                          :value="1"
                        ></v-radio>
                        <v-radio
                          label="NO"
                          :value="2"
                        ></v-radio>
                      </v-radio-group>

                      <label v-if="contacto.concepto == 2">¿El tiempo de respuesta entre el alumno y el asesor académico es adecuado? </label>
                      <v-radio-group
                        v-if="contacto.concepto == 2"
                        v-model="folios.valor7"
                        row
                        dense
                        readonly
                        hide-details
                        class="mt-0 mb-4"
                      >
                        <v-radio
                          label="SI"
                          :value="1"
                        ></v-radio>
                        <v-radio
                          label="NO"
                          :value="2"
                        ></v-radio>
                      </v-radio-group>

                      <label v-if="contacto.concepto == 2">¿El asesor resuelve las situaciones y dudas del alumno?</label>
                      <v-radio-group
                        v-if="contacto.concepto == 2"
                        v-model="folios.valor8"
                        row
                        dense
                        readonly
                        hide-details
                        class="mt-0 mb-4"
                      >
                        <v-radio
                          label="SI"
                          :value="1"
                        ></v-radio>
                        <v-radio
                          label="NO"
                          :value="2"
                        ></v-radio>
                      </v-radio-group>

                      <label v-if="contacto.concepto == 2">¿El asesor envia el recordatorio de pago en tiempo y forma?</label>
                      <v-radio-group
                        v-if="contacto.concepto == 2"
                        v-model="folios.valor9"
                        row
                        dense
                        readonly
                        hide-details
                        class="mt-0 mb-4"
                      >
                        <v-radio
                          label="SI"
                          :value="1"
                        ></v-radio>
                        <v-radio
                          label="NO"
                          :value="2"
                        ></v-radio>
                      </v-radio-group>

                      <label v-if="contacto.concepto == 2">¿El asesor se dijere de manera formal y educada con el alumno?</label>
                      <v-radio-group
                        v-if="contacto.concepto == 2"
                        v-model="folios.valor10"
                        row
                        dense
                        readonly
                        hide-details
                        class="mt-0 mb-4"
                      >
                        <v-radio
                          label="SI"
                          :value="1"
                        ></v-radio>
                        <v-radio
                          label="NO"
                          :value="2"
                        ></v-radio>
                      </v-radio-group>
                      
                      <v-textarea
                        v-model="folios.retroalimentacion"
                        :rows="6"
                        auto-grow
                        filled
                        dense
                        readonly
                        label="Escribe una Retroalimentación"
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
           
          </v-row>

          <v-divider></v-divider>

          <v-card class="shadowCard mt-4">
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <label>Aceptar o rechazar</label>
                  <v-radio-group
                    v-model="estatusRequi"
                    row
                    hide-details
                  >
                    <v-radio
                      label="Aceptar"
                      :value="1"
                    ></v-radio>
                    <v-radio
                      label="Rechazar"
                      :value="2"
                    ></v-radio>
                  </v-radio-group>
                </v-col>

                <v-col cols="12" v-if="estatusRequi == 2">
                  <v-textarea
                    v-model="mensaje_rechazo"
                    :rows="3"
                    auto-grow
                    filled
                    dense
                    label="Escribe un motivo"
                  >
                  </v-textarea>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                small
                tile
                color="green"
                dark
                @click="save()"
              >
                <v-icon>mdi-content-save</v-icon>
                Guardar  
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="black"
            tile
            dark
            small
            @click="close"
          >
            <v-icon small left>mdi-cancel</v-icon>
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Ver evidencias -->
    <v-dialog v-model="dialogImagen" max-width="1000px">
      <v-card class="elevation-0">
        <v-card-title>
          <v-spacer></v-spacer>
          <v-btn fab small text @click="dialogImagen = false"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-img
            :src=" url + imagen" 
            contain
            max-height="80vh"
          >
          </v-img>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialogo para eliminar -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-text>
          <v-row justify="space-around">
            <v-col cols="6">
              <v-card class="elevation-0" height="100%" style="display: table;">
                <div class="text-subtitle-1" style="display: table-cell; vertical-align: middle;">
                  <strong>Eliminar</strong> ¿Estás seguro que deseas eliminar el ciclo?
                  <v-card-actions class="pt-6">
                    <!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
                    <v-btn color="error" text  class="mr-2" @click="closeDelete()">No, cancelar</v-btn>
                    <!-- Guardar la información  -->
                    <v-btn color="#5C5C5C"  dark class="elevation-6"  @click="deleteItemConfirm()">Si</v-btn>
                  </v-card-actions>
                </div>
              </v-card>
            </v-col>
            <v-col cols="5">
              <v-img src="@/assets/borrar.png"></v-img>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-container>
</template>
<script>
import axios from 'axios';
  import { mapGetters } from 'vuex'
  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'
  import leerImagenes          from '@/mixins/leerImagenes'


  export default {

    mixins: [ validarErrores, funcionesExcel, leerImagenes ],

    components:{
      Alerta,
      carga,
    },

    data: () => ({
      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      dialog: false,
      dialogDelete: false,

      editedIndex: -1,

      editedItem: {
        idretroalimentacion:0,
        id_plantel:0,
        plantel:'',
        iniciales:'',
        calle:'',
        numero:0,
        colonia:'',
        estado:'',
        cp:0,
        telefono:'',
        link_maps:'',
        fecha_alta:null,
        activo_sn:0,
        fecha_baja:null,
        id_usuario_ultimo_cambio:0,
        fecha_ultimo_cambio:null,
        capacidad:0,
        link_zoom:'',
        tel_principal:'',
        tel_secundario:'',
        wpp_ventas:'',
        wpp_sucursal:'',
      },

      defaultItem: {
        idretroalimentacion:0,
        id_plantel:0,
        plantel:'',
        iniciales:'',
        calle:'',
        numero:0,
        colonia:'',
        estado:'',
        cp:0,
        telefono:'',
        link_maps:'',
        fecha_alta:null,
        activo_sn:0,
        fecha_baja:null,
        id_usuario_ultimo_cambio:0,
        fecha_ultimo_cambio:null,
        capacidad:0,
        link_zoom:'',
        tel_principal:'',
        tel_secundario:'',
        wpp_ventas:'',
        wpp_sucursal:'',
      },

      // Filtros:
      incio:null,
      fin:null,

      // tabla
      search:'',
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      retros: [],
      headers: [
        { text: 'ID'        , value: 'idretroalimentacion' },
        { text: 'Evalua'    , value: 'evalua'              },
        { text: 'Evaluado'  , value: 'evaluado'            },
        { text: 'Promedio'  , value: 'promedioTexto'       },
        { text: 'Concepto'  , value: 'concepto'            },
        { text: 'Estatus'   , value: 'estatus'             },
        { text: 'Actions'   , value: 'actions', sortable: false },
      ],

      ciclos:[],

      usuarios:[],

      editProspecto:{
        idprospectos: 0,
        folio: '',
        telefono: '',
        foto:'',
        calificacion: 0,
      },

      contactos: [],
      prospectos:[],
      cantidadContactos: 0,
      file: '',
      dragging: false,
      vistaPrevia: null,
      contacto:{},
      url: null,
      imagen: null,
      dialogImagen: false,

      concepto: 3,
      estatus: 3,

      fechaini:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      fechafin:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

      ciclo: null,
      dialogEdit: false,
      mensaje_rechazo:'',
      estatusRequi: null
    }),

    computed: {
      ...mapGetters('login',['getdatosUsuario']),
      formTitle () {
        return this.editedIndex === -1 ? 'Agregar retroalimentación' : 'Editar retroalimentación'
      },

      filterRetros( ){

        let retros = this.retros

        if( this.concepto != 3 ){  retros = retros.filter( el => el.concepto == this.concepto ) } 
        if( this.estatus != 3 ){  retros = retros.filter( el => el.estatus == this.estatus ) } 

        return retros

      }
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },

      ciclo( value ){
        console.log( value )
        this.fechafin = value ? value.fecha_fin_ciclo.substr( 0,10) : this.fechafin
        this.fechaini = value ? value.fecha_inicio_ciclo.substr( 0,10) : this.fechaini

        if( value ){
          this.initialize( )
        }
      }

    },

    async created () {
      this.url = axios.defaults.baseURL  + 'imagenes-retro/'
      await this.initialize()
      await this.getCiclosActivos( )
      await this.getUsuarios()
      await this.getProspectos( )
    },

    methods: {
      initialize () {
        this.cargar = true
        this.retros = []

        const payload = {
          fechafin: this.fechafin,
          fechaini: this.fechaini,
          id_usuario: this.getdatosUsuario.iderp
        }

        return this.$http.post('retroalimentacion.personal.all', payload ).then(response=>{
          this.retros = response.data.filter( el => el.id_evaluado == this.getdatosUsuario.iderp && el.validado == 1 )
          console.log( this.retros )
          this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getUsuarios( ){
        this.usuarios = []
        this.$http.get('usuarios.all.erp').then(response=>{
          for(const i in response.data){
            response.data[i].datos_completos = response.data[i].datos_completos.toUpperCase()
          }
          this.usuarios = response.data
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getProspectos( ){
        this.cargar    = true
        this.prospectos = []

        return this.$http.get('inscripciones.prospectos').then(response=>{
          this.prospectos = response.data.filter( el => el.finalizo == 0 )
          this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getCiclosActivos(){
        this.ciclos = []
        this.$http.get('kpi.ciclos.all').then(response=>{
          this.ciclos = response.data
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      editItem (item) {
        this.editedIndex = this.retros.indexOf(item)
        this.contacto    = Object.assign({}, item)
        this.dialogEdit = true
      },

      deleteItem (item) {
        this.editedIndex = this.retros.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        this.cargar = true
        // Lo mandapos por el EP
        this.editedItem['id_usuario'] = this.getdatosUsuario.iderp
        this.$http.put('retros.eliminar/' + this.editedItem.id_plantel, this.editedItem).then(response=>{
          this.cargar = true
          this.closeDelete()
          this.initialize()
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      close () {
        this.dialog = false
        this.dialogEdit = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      updateFoto( contacto ){

        // Verificar que el archivo existe
        if (!contacto.file) {
          this.validarErrorDirecto("No se ha seleccionado un archivo.");
          return;
        }

        // Crear una instancia de FormData
        let formData = new FormData();
        formData.append('file', contacto.file);

        // Crear vista previa utilizando FileReader
        const reader = new FileReader();
        reader.readAsDataURL(contacto.file); // Acceder correctamente al archivo desde contacto.file

        reader.onload = () => {
          contacto.url = reader.result
          contacto.formData = formData 
          contacto.image_name = contacto.file.name
        };
      },


      addCantidad () {

        if( this.cantidadContactos < 5 ){
          this.cantidadContactos += 1
          this.contactos.push({
            idprospectos: 0,
            folio: '',
            telefono: '',
            foto:'',
            calificacion: 0,
          })
        }
      },

      menosCantidad () {
        if( this.cantidadContactos > 1 ){ 
          this.cantidadContactos -= 1 
          this.contactos.pop()
        }
      },

      save ( estatus ) {

        const payload = {
          idretroalimentacion: this.contacto.idretroalimentacion,
          mensaje_rechazo: this.mensaje_rechazo, 
          estatus: this.estatusRequi,
          deleted: 0,
          validado: this.contacto.validado
        }

        this.$http.post('retroalimentacion.update', payload ).then(response=>{
          this.contactos  = []
          this.cantidadContactos = 0
          this.initialize()
          this.cargar = false
          this.close()
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })


        // }
      },

      verImagen( imagen ){
        this.imagen       = imagen
        this.dialogImagen = true
      },
    },
  }
</script>


<style scoped>
  .dropZone {
    width: 80%;
    height: 150px;
    position: relative;
    border: 2px dashed #eee;
  }

  .dropZone:hover {
    border: 2px solid #D8D8D8;
  }

  .dropZone:hover .dropZone-title {
    color: #1975A0;
  }

  .dropZone-info {
    color: #A8A8A8;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translate(0, -50%);
    text-align: center;
  }

  ..dropZone-title {
    color: #787878;
  }

  .dropZone input {
    position: absolute;
    cursor: pointer;
    top: 0px;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  .dropZone-upload-limit-info {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }

  .dropZone-over {
    background: #D8D8D8;
    opacity: 0.8;
  }

  .dropZone-uploaded {
    width: 80%;
    height: 200px;
    position: relative;
    border: 2px dashed #eee;
  }

  .dropZone-uploaded-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #D8D8D8;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translate(0, -50%);
    text-align: center;
  }

  .removeFile {
    width: 200px;
  }
</style>